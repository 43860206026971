.TeamPhoto {
  max-width: calc(100% - 40px);
  margin: 20px;
}

.Section {
  background-color: var(--sp-bg);
  position: relative;
  overflow: hidden;
}

.Section-content {
  display: grid;
  justify-content: center;
  
}

.Section-image-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Section-bg-div {
  min-width: calc(100vw * 1.4);
  position: absolute;
  top: 50%;
  left: 50%;
  overflow:visible;
}

.Section-video-bg {
  position: relative;
  margin-top: -25%;
  margin-left: -50%;
  width: 100%;
  height: 100%;
}

.Section-image-bg > * > h1 {
  text-shadow: 0 0 30px rgb(0, 0, 0);
}

.Section-image-bg > * > h3 {
  text-shadow: 0 0 20px rgb(0, 0, 0), 0 0 30px rgb(0, 0, 0);
}

.Section-image-bg > * > h5 {
  text-shadow: 0 0 20px rgb(0, 0, 0), 0 0 30px rgb(0, 0, 0);
}

.Section-image-bg > * > p {
  text-shadow: 0 0 20px rgb(0, 0, 0), 0 0 30px rgb(0, 0, 0), 0 0 50px rgb(0, 0, 0);
}

.Section-item {
  padding: 20px;
}

.Section-left {
  grid-column-start: l1;
  grid-column-end: le;
  justify-self: center;
  align-self: center;
  max-width: 360px;
  z-index: 1;
}

.Section-right {
  grid-column-start: l1;
  grid-column-end: le;
  justify-self: center;
  align-self: center;
  max-width: 360px;
  z-index: 1;
}

.Center-object {
  width: 80vw;
  max-width: 650px;
}

.Section-high {
  min-height: 66.6vw;
  z-index: 1;
}

@media only screen and (min-width: 750px) {
  .s1 {
    grid-template-columns: [l1] 1fr [le];
  }

  .s2 {
    grid-template-columns: [l1] 1fr [s2-l2] 1fr [le];
  }

  .s3 {
    grid-template-columns: [l1] 1fr [s3-l2] 1fr [s3-l3] 1fr [le];
  }

  .Section-left {
    max-width: 360px;
    margin-left: 165px;
    justify-self: left;
  }

  .Section-right {
    max-width: 360px;
    margin-right: 165px;
    justify-self: right;
  }

  .Section-high {
    min-height: 500px;
  }
}

@media only screen and (min-width: 1200px) {
  .s1 {
    grid-template-columns: [l1] 1200px [le];
  }

  .s2 {
    grid-template-columns: [l1] 600px [s2-l2] 600px [le];
  }

  .s3 {
    grid-template-columns: [l1] 400px [s3-l2] 400px [s3-l3] 400px [le];
  }

  .s6 {
    grid-template-columns: [l1] 200px [s6-l2] 200px [s6-l3] 200px [s6-l4] 200px [s6-l5] 200px [s6-l6] 200px [le];
  }

  .s6 {
    grid-template-columns: [l1] 240px [s5-l2] 240px [s5-l3] 240px [s5-l4] 240px [s5-l5] 240px [le];
  }

  .Section-left {
    margin-left: calc((2 * 165px) - 13.75vw);
  }

  .Section-right {
    margin-right: calc((2 * 165px) - 13.75vw);
  }
}

.Section-centered {
  grid-column-start: l1;
  grid-column-end: le;
  justify-self: center;
  align-self: center;
}

.Text-center {
  text-align: center;
}

.Text-sp {
  color: var(--sp-color);
}

.Text-primary {
  color: var(--sp-prim-color);
}

.Bg-light {
  background-color: var(--sp-light-color);
}

.Primary {
  background-color: var(--sp-color);
  color: var(--sp-prim-color);
}

.Button {
  background-color: var(--sp-color);
  color: var(--sp-prim-color);
  padding: 10px;
  border-radius: 4px;
  border: 0px;
  margin: 2px;
  min-width: 140px;
}

.Socials-button {
  min-width: 65px;
  min-height: 65px;
  border-radius: 12px;
  display: grid;
  grid-template-columns: [l1] 40px [l2] auto [le];
  align-content: center;
  justify-content: center;
}

.Socials-name {
  font-size: 20px;
  padding-left: 6px;
  padding-right: 4px;
  text-align: center;
  align-self: center;
  display: none;
}

@media only screen and (min-width: 1200px) {
  .Socials-name {
    display: block;
  }
}

.Button:hover {
  filter: brightness(110%);
  cursor: pointer;
}

.Center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectThumb-image {
  max-width: min(500px, 100%);
}

.ProjectThumb {
  border: solid var(--sp-color) 1px;
  border-radius: 8px;
  padding: 20px;
  background-color: var(--sp-light-color);
  color: var(--sp-color);
}

.ProjectThumb:hover {
  filter: brightness(110%);
}

.Footer {
  margin-top: 15px;
  border-top: solid var(--sp-color) 1px;
  padding: 10px;
}

.Message-error {
  color: darkred;
  border: solid darkred 1px;
  border-radius: 6px;
  background-color: lightpink;
  padding: 20px;
}

.Message-error > a {
  color: darkred;
  text-decoration: underline;
}